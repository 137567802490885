import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Tab from '../Components/AutoLayout/Tab'
import TableList from './TableList'

const tab = [
  {
    id: 'chart',
    label: 'Chart',
  },
  {
    id: 'table',
    label: 'Table',
  },
]

const options = {
  chart: {
    type: 'column',
  },
  title: {
    text: 'Column Chart Example',
  },
  xAxis: {
    categories: ['Category 1', 'Category 2', 'Category 3'],
  },
  yAxis: {
    title: {
      text: 'Values',
    },
  },
  series: [
    {
      name: 'Series 1',
      data: [5, 10, 15],
    },
    {
      name: 'Series 2',
      data: [8, 12, 10],
    },
  ],
}

function Report() {
  const [activeTabId, setActiveTabId] = useState('chart')

  return (
    <div>
      <div className="mb-3">
        <Tab
          data={tab}
          activeTabId={activeTabId}
          setActiveTabId={setActiveTabId}
        />
      </div>
      {activeTabId === 'chart' && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
      {activeTabId === 'table' && <TableList />}
    </div>
  )
}

export default Report
