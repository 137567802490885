export const confirmSwal = (action, data) => {
  window.Swal.fire({
    title: 'Apakah anda yakin?',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya',
    cancelButtonText: 'Batal',
  }).then((result) => {
    if (result.isConfirmed) {
      action(data)
    }
  })
}
