import { ErrorMessage } from '@hookform/error-message'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { encryptAES } from '../../Utils/EncryptUtils'

function ChangePassword() {
  // prettier-ignore
  const { register, handleSubmit, formState: { errors }, watch } = useForm()
  // redux
  const user = useSelector((state) => state.user)

  const handleChangePassword = async ({
    oldPassword,
    newPassword,
    confirmPassword,
  }) => {
    const secretKey = moment().locale('en').format('dddYYYYMMDD')

    const encryptedPassword = encryptAES(oldPassword, secretKey)
    const encryptedNewPassword = encryptAES(newPassword, secretKey)
    const encryptedConfirmPassword = encryptAES(confirmPassword, secretKey)

    const payload = {
      type: 'change',
      userid: user.id,
      password: encryptedPassword,
      newpwd: encryptedNewPassword,
      repassword: encryptedConfirmPassword,
    }

    console.log(payload)
  }

  const validatePasswordMatch = (value) => {
    // get the value of the "password" field using the watch function
    const newPassword = watch('newPassword')
    // compare the value with the value of the "password" field
    return value === newPassword ? true : 'Kata sandi tidak cocok'
  }

  return (
    <div className="card w-100 card-info" id="changePassword">
      <div className="card-header">
        <h3 className="card-title">Ganti Kata Sandi</h3>
        <div className="card-tools m-0">
          <button
            type="button"
            className="btn btn-tool"
            data-card-widget="collapse"
          >
            <i className="fas fa-minus"></i>
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Kata Sandi Saat Ini
                <span className="text-danger font-weight-bold"> *</span>
              </label>
              <input
                type="password"
                id="oldPassword"
                className="form-control form-control-sm"
                name="oldPassword"
                {...register('oldPassword', {
                  required: 'Kata Sandi Saat Ini harus diisi',
                  minLength: {
                    value: 8,
                    message: 'Minimal Kata Sandi Saat Ini 8 karakter',
                  },
                  maxLength: {
                    value: 30,
                    message: 'Maksimal Kata Sandi Saat Ini 30 karakter',
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="oldPassword"
                as="div"
                style={{ color: 'red', marginTop: '5px' }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Kata Sandi Baru
                <span className="text-danger font-weight-bold"> *</span>
              </label>
              <input
                type="password"
                id="newPassword"
                className="form-control form-control-sm"
                name="newPassword"
                {...register('newPassword', {
                  required: 'Kata Sandi Baru harus diisi',
                  minLength: {
                    value: 8,
                    message: 'Minimal Kata Sandi Baru 8 karakter',
                  },
                  maxLength: {
                    value: 30,
                    message: 'Maksimal Kata Sandi Baru 30 karakter',
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="newPassword"
                as="div"
                style={{ color: 'red', marginTop: '5px' }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Konfirmasi Kata Sandi Baru
                <span className="text-danger font-weight-bold"> *</span>
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="form-control form-control-sm"
                name="confirmPassword"
                {...register('confirmPassword', {
                  required: 'Konfirmasi Kata Sandi Baru harus diisi',
                  minLength: {
                    value: 8,
                    message: 'Minimal Konfirmasi Kata Sandi Baru 8 karakter',
                  },
                  maxLength: {
                    value: 30,
                    message: 'Maksimal Konfirmasi Kata Sandi Baru 30 karakter',
                  },
                  validate: validatePasswordMatch,
                })}
              />
              <ErrorMessage
                errors={errors}
                name="confirmPassword"
                as="div"
                style={{ color: 'red', marginTop: '5px' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <button
          className="btn btn-sm btn-info"
          type="button"
          onClick={handleSubmit(handleChangePassword)}
        >
          Simpan
        </button>
      </div>
    </div>
  )
}

export default ChangePassword
