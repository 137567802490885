import {
  removeHiddenField,
  setHiddenField,
} from '../Store/HiddenElement/hiddenElementSlice'

const handleShowValue = (fieldItem) => {
  if (fieldItem.value !== '') return fieldItem.value
  if (fieldItem.valueList?.length) return fieldItem.valueList[0]?.value
  // default
  return ''
}

export const handleConditionValue = (
  currentValue,
  panelList,
  condition,
  dispatch,
  setValue
) => {
  condition.forEach((conditionItem) => {
    if (conditionItem.value.includes(currentValue)) {
      conditionItem.setting.forEach((setting) => {
        const targetEl = document.getElementById(setting.id)
        if (setting.property.visible === '1') {
          // SHOW CONDITION
          // condition for input / select element
          if (setting.type === 'field') {
            dispatch(removeHiddenField(setting.id))
          }
          // condition for panel element
          else {
            let visibleFieldId = []
            targetEl.style.display = 'block'
            const filteredPanel = panelList.find(
              (panelItem) => panelItem.panelId === setting.id
            )
            // set every field value inside panel to '' (empty string)
            filteredPanel.listField.forEach((fieldItem) => {
              visibleFieldId.push(fieldItem.id)
              // setValue(fieldItem.id, handleShowValue(fieldItem))
            })
            dispatch(removeHiddenField(visibleFieldId))
          }
        } else {
          // HIDE CONDITION
          let hiddenFieldId = []
          if (setting.type === 'field') {
            hiddenFieldId.push(setting.id)
          } else {
            targetEl.style.display = 'none'
            // prettier-ignore
            const filteredPanel = panelList.find((panelItem) => panelItem.panelId === setting.id)
            // set every field value inside panel to '-'
            filteredPanel.listField.forEach((fieldItem) => {
              hiddenFieldId.push(fieldItem.id)
            })
          }
          dispatch(setHiddenField(hiddenFieldId))
        }
      })
    }
  })
}
