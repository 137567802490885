import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filtering: [],
  currentPayload: {},
}

export const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    setFilteringList: (state, action) => {
      state.filtering = action.payload
    },
    setCurrentPayload: (state, action) => {
      state.currentPayload = action.payload
    },
    reset: (state) => {
      return initialState
    },
  },
})

export const { setFilteringList, setCurrentPayload, reset } = listSlice.actions

export const listReducer = listSlice.reducer
