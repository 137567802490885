import { configureStore } from '@reduxjs/toolkit'
import { listReducer } from './List/listSlice'
import { menuReducer } from './Menu/menuSlice'
import { userReducer } from './User/userSlice'
import { nodeReducer } from './Flowchart/nodeSlice'
import { edgeReducer } from './Flowchart/edgeSlice'
import { elementReducer } from './Flowchart/elementSlice'
import { hiddenElementReducer } from './HiddenElement/hiddenElementSlice'
import { dropdownReducer } from './Input/DropdownSlice'
import { loadingReducer } from './Loading/LoadingSlice'
import { formReducer } from './Form/FormSlice'

export const store = configureStore({
  reducer: {
    // register your reducer here
    menu: menuReducer,
    hiddenElement: hiddenElementReducer,
    user: userReducer,
    list: listReducer,
    node: nodeReducer,
    edge: edgeReducer,
    element: elementReducer,
    dropdown: dropdownReducer,
    loading: loadingReducer,
    form: formReducer,
  },
})
